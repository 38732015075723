<template>
  <OffersAsideLayout
    id="proposals"
    ref="proposals"
    class="proposals"
  >
    <template
      v-slot:header
    >
      <ProposalsListHeader />
    </template>

    <ProposalsList
      v-if="!$wait.is('fetching proposals') && getProposals.length"
      class="tw-min-h-0"
      data-test="list"
    />

    <ProposalsEmptyState
      v-if="!$wait.is('fetching proposals') && !getProposals.length"
      :title="$t('offer_list.main_content.no_proposal_available')"
      data-test="empty"
    />

    <CtkLoadingLayer
      v-if="$wait.is('fetching proposals') && isAfterTimeout"
      data-test="loading"
    >
      {{ $t('app.loading') }}
    </CtkLoadingLayer>
  </OffersAsideLayout>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent, onMounted, watch } from '@vue/composition-api'

  import OffersAsideLayout from '@/views/Carriers/Offers/components/OffersAsideLayout/index.vue'
  import ProposalsListHeader from '@/views/Carriers/Offers/components/ProposalsList/_subs/ProposalsListHeader/index.vue'
  import ProposalsList from '@/views/Carriers/Offers/components/ProposalsList/index.vue'
  import ProposalsListFilters from '@/views/Carriers/Offers/components/ProposalsList/_subs/ProposalsListFilters/index.vue'
  import ProposalsEmptyState from '@/views/Carriers/Offers/components/ProposalsEmptyState/index.vue'
  import CtkListHeader from '@/components/CtkList/_subs/CtkListHeader/index.vue'
  import CtkLoadingLayer from '@/components/CtkLoadingLayer/index.vue'

  import store from '@/store'
  import useStore from '@/composables/useStore'
  import useWait from '@/composables/useWait'
  import useRoute from '@/composables/useRoute'
  import useAfterTimeout from '@/composables/useAfterTimeout'
  import { LOADING_TIMEOUT_IN_MS } from '@/composables/constants'

  export default defineComponent({
    name: 'Proposals',
    metaInfo () {
      return {
        title: this.$t('proposals.title')
      }
    },
    components: {
      OffersAsideLayout,
      CtkLoadingLayer,
      ProposalsList,
      ProposalsListHeader,
      ProposalsListFilters,
      ProposalsEmptyState,
      CtkListHeader
    },
    setup () {
      const store = useStore()
      const wait = useWait()
      const route = useRoute()
      const { isAfterTimeout, resetTimeout } = useAfterTimeout(LOADING_TIMEOUT_IN_MS)

      /**
       * @function fetchProposals
       * @param {object} route
       * @param {object} route.params
       * @param {string|null} route.params.state
       * @returns {Promise<any>}
       */
      function fetchProposals (route) {
        const { state } = route.params

        startFetchingProposalsWaiter()
        return Promise.all([
          store.value.dispatch('offers/retrieveProposalsMetrics', { root: true }),
          store.value.dispatch('offers/retrieveProposalsByStatus', {
            state: state || null
          }, { root: true })
        ])
          .catch(() => {})
          .finally(() => {
            wait.end('fetching proposals')
            store.value.dispatch('setAppReady', true, { root: true })
          })
      }

      function startFetchingProposalsWaiter () {
        resetTimeout()
        wait.start('fetching proposals')
      }

      onMounted(async () => {
        await fetchProposals(route.value)
      })

      watch(route, async (to) => {
        await fetchProposals(to)
      })

      return {
        isAfterTimeout
      }
    },
    // @ts-ignore
    beforeRouteUpdate (to, from, next) {
      store.dispatch('offers/resetCurrentProposal')
      store.dispatch('offers/resetCurrentOffer')
      next()
    },
    computed: {
      ...mapGetters('offers', [
        'getProposals'
      ])
    }
  })
</script>

<style lang="scss" scoped>

  .proposals {
    .load-layer {
      z-index: 2;
    }

    .ctk-proposals-list__header {
      &::after {
        left: 1rem;
        width: calc(100% - 2rem);
      }
    }
  }

</style>
