<template>
  <button
    :class="{
      'ctk-proposal-item--active': isActive
    }"
    class="ctk-proposal-item tw-relative tw-bg-white hover:tw-bg-gray-100 focus:tw-outline-none focus-visible:tw-outline-solid-blue tw-border-none tw-p-0 tw-text-sm tw-w-full tw-text-gray-900 hover:tw-text-gray-900 tw-transition-colors tw-transition-shadow tw-text-left"
    @click="click"
  >
    <div
      class="tw-flex tw-flex-col ctk-proposal-item__link tw-px-5 tw-pt-6 tw-pb-2  tw-mb-4"
    >
      <div
        class="tw-text-blue-500 tw-mb-2"
        v-text="$t(proposal.pickup_date && proposal.delivery_date
          ? proposal.price
            ? 'offer_list.item.proposals.amount_dates'
            : 'offer_list.item.proposals.me_dates'
          : 'offer_list.item.proposals.amount')"
        data-test="proposal-type"
      />
      <proposal-item-content
        :proposal="proposal"
      />
      <initial-offer-triangle
        v-if="proposal"
        :proposal="proposal"
        :currency="proposal.offer.pricing.currency"
      />
    </div>
  </button>
</template>

<script>
  import { defineComponent, toRefs } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import InitialOfferTriangle from '@/views/Carriers/Offers/components/OffersList/_subs/InitialOfferTriangle/index.vue'
  import ProposalItemContent from './_subs/ProposalItemContent/index.vue'

  export default defineComponent({
    name: 'ProposalItem',
    components: {
      InitialOfferTriangle,
      ProposalItemContent
    },
    props: {
      proposal: {
        type: Object,
        required: true
      }
    },
    setup (props, { emit }) {
      const { proposal } = toRefs(props)

      function click () {
        emit('click', proposal.value)
      }

      return {
        click
      }
    },
    computed: {
      ...mapGetters('offers', [
        'getCurrentOffer',
        'getCurrentProposal'
      ]),
      isActive () {
        if (this.getCurrentOffer) {
          return this.proposal.offer && this.getCurrentOffer.uuid === this.proposal.offer.uuid
        }

        if (this.getCurrentProposal) {
          return this.getCurrentProposal && this.proposal.uuid === this.getCurrentProposal.uuid
        }

        return false
      }
    }
  })
</script>

<style lang="scss" scoped>
.ctk-proposal-item--active {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 244, 246, var(--tw-bg-opacity));
}
.ctk-proposal-item:hover {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0/0.1),0 2px 4px -2px rgb(0 0 0/0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),0 2px 4px -2px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  z-index: 10;
}
.ctk-proposal-item:not(:last-child)::after {
  --tw-bg-opacity: 1;
  background-color: rgba(183, 183, 183, var(--tw-bg-opacity));
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  content: '';
  height: 1px;
}
</style>
